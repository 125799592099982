import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import ProgContext from "../../../Context/ProgContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditFastprog = ({ selectedItem, formateur }) => {
  const { url, user } = useContext(AppContext);

  const { setMsg } = useContext(ProgContext);

  const [progression, setProgression] = useState(null);
  const [formateurs, setFormateurs] = useState(null);
  const [msgLocal, setMsgLocal] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (selectedItem !== null) {
      if (user.role > 3) {
        setProgression({
          ...selectedItem.prog,
          formateur: formateur["@id"],
          appel: false,
        });
        setLoaded(true);
      } else {
        loadFormateuurs();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const loadFormateuurs = () => {
    let uri =
      url +
      "api/formateurs?promos=" +
      selectedItem.uriPromo +
      "&matieres=" +
      selectedItem.matiere;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"]);
        let tmp = [];
        if (formateur) {
          tmp = res["hydra:member"].filter(
            (f) => f["@id"] === formateur["@id"]
          );
        }
        setProgression({
          ...selectedItem.prog,
          formateur: tmp.length > 0 ? tmp[0]["@id"] : null,
        });
        setLoaded(true);
      } else {
        setMsgLocal({
          txt: "Erreur de chargement des formateurs",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/progressions";
    PostBddProvider(uri, progression).then((res) => {
      setLoaded(true);
      if (typeof res === "object" && res.id) {
        setProgression({
          ...progression,
          description: undefined,
          time: undefined,
          activate: false,
          appel: false,
        });
        setMsg({ txt: "Progression enregistrée", type: "success" });
        setMsgLocal({ txt: "Progression enregistrée", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        setMsgLocal({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalFastProg"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;Progression pédagodique
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msgLocal !== null ? (
                <div className="mx-4">
                  <MsgAlert
                    msg={msgLocal.txt}
                    type={msgLocal.type}
                    close={() => autoCloseMsg(setMsg)}
                  />
                </div>
              ) : null}
              {!loaded || progression === null ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={progression.createdAt || ""}
                        onChange={(e) => {
                          if (
                            new Date(e.target.value) > new Date() ||
                            e.target.value === ""
                          ) {
                            setProgression({
                              ...progression,
                              activate: false,
                              appel: false,
                              createdAt: e.target.value,
                            });
                          } else {
                            setProgression({
                              ...progression,
                              createdAt: e.target.value,
                            });
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>
                        Durée{" "}
                        <em>
                          <small>(de 00:15 à 04:00)</small>
                        </em>{" "}
                      </label>
                      <input
                        type="time"
                        title="Heures entre 00:15 et 04:00"
                        min="00:15"
                        max="04:00"
                        step="900"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={progression.time || "00:00"}
                        className="form-control"
                        onChange={(e) =>
                          setProgression({
                            ...progression,
                            time: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <em>Promo</em>
                    </div>
                    <strong>
                      <em>
                        <u>{selectedItem.promo}</u>
                      </em>
                    </strong>
                  </div>

                  <div className="form-group">
                    <div>
                      <em>Elément de progression</em>
                    </div>
                    <strong>
                      <em>
                        <u>{selectedItem.progressionItem}</u>
                      </em>
                    </strong>
                  </div>
                  {user.role <= 3 ? (
                    <div className="form-group">
                      <hr />
                      <label>Formateur</label>
                      <select
                        className="form-control"
                        value={progression.formateur || ""}
                        onChange={(e) => {
                          setProgression({
                            ...progression,
                            formateur: e.target.value,
                          });
                        }}
                        required
                      >
                        <option value="" disabled>
                          Sélectionnez un formateur
                        </option>
                        {formateurs.map((f, i) => (
                          <option value={f["@id"]} key={i}>
                            {f.user.firstName} {f.user.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  <div className="form-group">
                    <hr />
                    <label>Description</label>
                    <textarea
                      type="textarea"
                      className="form-control"
                      placeholder="Description de la session"
                      required
                      rows="3"
                      maxLength="250"
                      value={progression.description || ""}
                      onChange={(e) =>
                        setProgression({
                          ...progression,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <div className="custom-control custom-switch p-0">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="inlineCheckbox"
                        checked={progression.activate || false}
                        disabled={
                          new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "" ||
                          !progression.description ||
                          progression.description.length < 10
                        }
                        onChange={() => {
                          setProgression({
                            ...progression,
                            activate: !progression.activate,
                            appel: progression.activate
                              ? false
                              : progression.appel,
                          });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="inlineCheckbox"
                      >
                        Réalisé
                      </label>
                      <div
                        className="d-flex flex-column"
                        class="form-text text-muted font-italic"
                      >
                        {(!progression.description ||
                          progression.description.length < 10) && (
                          <small className="d-block lh-1 font-small mb-1">
                            - La description doit contenire au moins 10
                            caractères, pour pouvoir cocher "Réalisée".
                          </small>
                        )}
                        {(new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "") && (
                          <small className="d-block lh-1 font-small">
                            - La date doit être antérieur ou égale à la datre du
                            jour, pour pouvoir cocher "Réalisée".
                          </small>
                        )}
                      </div>
                    </div>
                    {/* <div className="custom-control custom-switch col-6">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="CheckboxAppel"
                        checked={progression.appel || false}
                        disabled={
                          new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "" ||
                          !progression.activate
                        }
                        onChange={() => {
                          setProgression({
                            ...progression,
                            appel: !progression.appel,
                          });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="CheckboxAppel"
                      >
                        Appel fait
                      </label>
                    </div> */}
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="submit" className="btn btn-info">
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFastprog;
