import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditCompAfest = ({ data, setMaj }) => {
  const { url } = useContext(AppContext);
  const [competence, setCompetence] = useState({});
  const [msg, setMsg] = useState(null);
  const [save, setSave] = useState(true);

  useEffect(() => {
    if (data !== null && competence !== data) {
      setCompetence(data);
      setMsg(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let uri = url + "api/competence_afests";
    if (competence["@id"]) {
      uri = url + competence["@id"].slice(1);
      data = { name: competence.name };
      if (competence.flow !== null && competence.flow !== undefined) {
        data = { ...data, flow: competence.flow };
      }
      UpdateBddProvider(uri, data).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            txt: "La compétence a bien été modifée",
            type: "success",
          });
          setMaj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({
            txt: "erreur d'enregistrement de la compétence",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      PostBddProvider(uri, competence).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            txt: "La compétence a bien été créée",
            type: "success",
          });
          setCompetence({ bloc: competence.bloc });
          setMaj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({
            txt: "erreur d'enregistrement de le compétence",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalComp"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" />
                &nbsp; {competence["@id"] ? "Modifer" : "Ajouter"} une
                compétence
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
              <div className="modal-body">
                {msg !== null ? (
                  <div className="mx-4">
                    <MsgAlert
                      msg={msg.txt}
                      type={msg.type}
                      close={() => autoCloseMsg(setMsg)}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="name">Nom de la compétence</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={competence.name || ""}
                    placeholder="Nom"
                    onChange={(e) =>
                      setCompetence({
                        ...competence,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="switchCompFilDeLEau"
                      checked={competence.flow || false}
                      onChange={() =>
                        setCompetence((prev) => ({ ...prev, flow: !prev.flow }))
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="switchCompFilDeLEau"
                    >
                      <strong>Fil de l'eau</strong>
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!competence.name}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCompAfest;
