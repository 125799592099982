import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";
import {
  getEleves,
  getPromo,
  getPromoGalia,
} from "../../tools/SyncAbsRtd/tools";
import RtdEleveEdusign from "./RtdEleveEdusign";

const RtdPromoEdusign = ({ cours, token, seance, connector }) => {
  const { url, urlEdusign, connectors } = useContext(AppContext);

  const periode = () => {
    let H;
    if (seance) {
      H = parseInt(seance.Fin.split(":")[0]);
    } else {
      H = new Date(cours.END).getHours();
    }
    if (H < 13) {
      return "Matin";
    } else {
      return "Après-midi";
    }
  };

  const _PERIODE = periode();

  const [msg, setMsg] = useState(null);
  const [promos, setPromos] = useState([]);
  const [load, setLoad] = useState(false);
  const [eleves, setEleves] = useState([]);
  const retardsEdusign = cours
    ? cours.STUDENTS.filter((s) => s.delay !== 0)
    : [];
  const departEdusign = cours
    ? cours.STUDENTS.filter((s) => s.earlyDeparture !== null)
    : [];

  useEffect(() => {
    if (cours.SCHOOL_GROUP.length > 0) {
      getPromo(url, urlEdusign, token, cours, setPromos, setMsg, setLoad);
    } else if (seance) {
      getPromoGalia(url, seance, setPromos, setMsg, setLoad);
    } else {
      setLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!seance && promos !== null && promos.length > 0) {
      getEleves(url, promos, setEleves, setMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promos]);

  // const getPromo = async () => {
  //   let groupsDetail = [];
  //   let error = [];
  //   for (const g of cours.SCHOOL_GROUP) {
  //     let uriGroup = urlEdusign["GROUPS"] + "/" + g;
  //     try {
  //       await GetBddProvider(uriGroup, token).then((res) => {
  //         if (typeof res === "object" && res.result) {
  //           groupsDetail.push(res.result);
  //         } else {
  //           error.push("erreur de chargment Edusign !");
  //         }
  //       });
  //     } catch (err) {
  //       error.push("erreur de chargment Edusign !");
  //     }
  //   }

  //   let uri = url + "api/promos?";
  //   groupsDetail.forEach((group, i, groups) => {
  //     uri += groups.length > 1 ? "IdEdusign[]=" : "IdEdusign=";
  //     uri += group.PARENT ? group.PARENT : group.ID;
  //     uri += i + 1 < groups.length ? "&" : "";
  //   });
  //   await GetBddProvider(uri).then((res) => {
  //     if (typeof res === "object") {
  //       setPromos(res["hydra:member"]);
  //     } else {
  //       error.push("Erreur de chargement de la promo");
  //     }
  //   });

  //   if (error.length > 0) {
  //     setMsg({ txt: error.join(", "), type: "warning" });
  //   }
  //   setLoad(true);
  // };

  // if (load && msg) {
  //   return (
  //     <div className="card my-2">
  //       <div className="card-header text-left p-2">
  //         <MsgAlert msg={msg.txt} type={msg.type} />
  //       </div>
  //     </div>
  //   );
  // }

  // !todo : revoir le retour jsx pour traiter comme les absences !

  if (!load) {
    return (
      <div className="card my-2">
        <SmallSpinner />
      </div>
    );
  }
  if (
    Object.values(connectors).some((v) => v === connector) &&
    cours.API_ID &&
    !seance
  ) {
    return (
      <div className="card text-white bg-warning my-2">
        <div className="card-header text-left p-2">
          <h5 className="text-left m-0">
            {cours.NAME} - La session est inexistante dans Galia !
          </h5>
        </div>
      </div>
    );
  }
  return (
    <div className="card my-2">
      <div className="card-header text-left p-2">
        <h5 className="text-left m-0">
          {seance
            ? `${seance.Libelle_Action} - ${seance.Libelle_Court}`
            : promos.length > 0
            ? `${promos.map((p) => p.name + " - ")}${cours.NAME}`
            : cours.NAME}
        </h5>
        <small className="mr-4">{dateFormated(cours.START)}</small>
        <br />
        <small className="mr-4">{_PERIODE}</small>
      </div>
      <div className="card-body p-0 text-left">
        {msg !== null ? (
          <MsgAlert
            msg={msg.txt}
            type={msg.type}
            close={() => autoCloseMsg(setMsg)}
          />
        ) : null}
        {retardsEdusign.length > 0 && (
          <ul className="list-group list-group-flush">
            <li className="list-group-item border-danger font-weight-bold text-danger bg-light">
              Retards :
            </li>
            {retardsEdusign.map((r) => {
              return (
                <RtdEleveEdusign
                  promos={promos}
                  connector={connector}
                  cours={cours}
                  seance={seance}
                  date={cours.START.split("T")[0]}
                  eventEdusign={r}
                  token={token}
                  periode={_PERIODE}
                  key={r._id}
                />
              );
            })}
          </ul>
        )}
        {departEdusign.length > 0 && (
          <ul className="list-group list-group-flush">
            <li className="list-group-item border-warning font-weight-bold text-warning bg-light">
              Départs anticipés :
            </li>
            {departEdusign.map((d) => {
              return (
                <RtdEleveEdusign
                  promos={promos}
                  connector={connector}
                  cours={cours}
                  seance={seance}
                  date={cours.START.split("T")[0]}
                  eventEdusign={d}
                  token={token}
                  periode={_PERIODE}
                  depart={true}
                  key={d._id}
                />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default RtdPromoEdusign;
