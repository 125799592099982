import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AppContext from "../../../Context/AppContext";
import DeleteBddProvider from "../../../Providers/DeleteBddProvider";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import addTime from "../../tools/addTime";
import BulletinPDF from "../../tools/BulletinPDF";
import convertDecimal from "../../tools/convertDecimal";
import dateFormated from "../../tools/dateFormated";
import heuresFormated from "../../tools/heuresFormated";
import EditAppreciationSem from "../edits/EditAppréciationSem";
import ModifCommentMat from "../modifs/ModifCommentMat";
import ViewAbsEleve from "../views/ViewAbsEleve";
import ViewRtdEleve from "../views/ViewRtdEleve";

import $ from "jquery";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";
import { ListSanctions } from "./ListSanctions";

const ListNotes = ({
  eleve,
  listNotes,
  promo,
  sanctions,
  motifs,
  setSanctionSelected,
  deleteSanction,
}) => {
  const matieres = promo.formation.matieres
    .filter((m) => !m.hidden)
    .sort(triByName);
  const idAccordion = "acoordionEl-" + eleve.id;

  const { url, user } = useContext(AppContext);

  const [moyennesMat, setMoyennesMat] = useState(null);
  const [moyennesSemestres, setMoyennesSemestres] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [notes, setnotes] = useState(null);
  const [absences, setAbsences] = useState(null);
  const [retards, setRetards] = useState(null);
  const [comments, setcomments] = useState(null);
  const [commentSelected, setCommentSelected] = useState(null);
  const [commentsSemestre, setCommentsSemestre] = useState(null);
  const [appSelected, setAppSelected] = useState(null);
  const [formateur, setFormateur] = useState(null);
  const [absSelected, setAbsSelected] = useState(null);
  const [rtdSelected, setRtdSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [semestreActif, setSemestreActif] = useState(null);
  const [maj, setMaj] = useState(false);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  useEffect(() => {
    loadFormateur();
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      init();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const loadFormateur = () => {
    let uri = url + "api/formateurs?user=" + user["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateur(res["hydra:member"][0]);
      }
    });
  };

  const load = () => {
    let uri = url + "api/comment_matieres?eleve=" + eleve["@id"] + "&";
    promo.semestres.forEach((sem, i, sems) => {
      uri += sems.length > 1 ? "semestre[]=" : "semestre=";
      uri += sem["@id"];
      uri += i + 1 < sems.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setcomments(res["hydra:member"]);
        let uriAbs = url + "api/absences?eleve=" + eleve["@id"] + "&";
        promo.semestres.forEach((sem, i, sems) => {
          uriAbs += sems.length > 1 ? "semestre[]=" : "semestre=";
          uriAbs += sem["@id"];
          uriAbs += i + 1 < sems.length ? "&" : "";
        });
        GetBddProvider(uriAbs).then((res) => {
          if (typeof res === "object") {
            setAbsences(res["hydra:member"]);
            let uriRtds = url + "api/retards?eleve=" + eleve["@id"] + "&";
            promo.semestres.forEach((sem, i, sems) => {
              uriRtds += sems.length > 1 ? "semestre[]=" : "semestre=";
              uriRtds += sem["@id"];
              uriRtds += i + 1 < sems.length ? "&" : "";
            });
            GetBddProvider(uriRtds).then((res) => {
              if (typeof res === "object") {
                setRetards(res["hydra:member"]);
                let uriComSem =
                  url + "api/comment_semestres?eleve=" + eleve["@id"] + "&";
                promo.semestres.forEach((sem, i, sems) => {
                  uriComSem += sems.length > 1 ? "semestre[]=" : "semestre=";
                  uriComSem += sem["@id"];
                  uriComSem += i + 1 < sems.length ? "&" : "";
                });
                GetBddProvider(uriComSem).then((res) => {
                  if (typeof res === "object") {
                    setCommentsSemestre(res["hydra:member"]);
                    setLoaded(true);
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  const deleteComment = (uri) => {
    setLoaded(false);
    DeleteBddProvider(url + uri.slice(1)).then((res) => {
      if (res === 204) {
        setMaj(true);
        setMsg({ txt: "Commentaire supprimé", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setLoaded(true);
        setMsg({ txt: "Erreur de supression", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const CommentGeneral = ({ semestre }) => {
    let appreciation = {
      semestre: semestre["@id"],
      eleve: eleve["@id"],
      comment: null,
    };
    commentsSemestre.forEach((c) =>
      c.semestre === semestre["@id"] ? (appreciation = c) : null
    );
    return (
      <div className="border border-info p-2 text-left">
        <div>
          <strong>
            <u>
              <em>Appréciation générale :</em>
            </u>
            {user.role <= 3 ? (
              <span
                className="text-info ml-2"
                style={{ cursor: "pointer" }}
                onClick={() => setAppSelected(appreciation)}
                data-toggle="modal"
                data-target="#ModalAppreciation"
              >
                <FontAwesomeIcon icon="edit" />
              </span>
            ) : null}
          </strong>
          <div className="m-3">
            {!semestre.validate && user.role > 4
              ? "Semestre non cloturé"
              : appreciation.comment !== null
              ? appreciation.comment.split("\n").map((c, i) => (
                  <React.Fragment key={i}>
                    {c}
                    <br />
                  </React.Fragment>
                ))
              : "Pas d'appréciation saisie"}
          </div>
        </div>
      </div>
    );
  };

  const init = () => {
    let tempNotes = {};
    let sActif = promo.semestres.filter((s) => s.actif);
    if (promo.semestres.length > 0) {
      setSemestreActif(
        sActif.length === 0
          ? promo.semestres[promo.semestres.length - 1]["@id"]
          : sActif[0]["@id"]
      );
    }

    listNotes.forEach((note) => {
      if (tempNotes[note.semestre] === undefined) {
        tempNotes = {
          ...tempNotes,
          [note.semestre]: {
            [note.matiere]: [
              {
                note: note.note,
                coef: note.coef,
                comment: note.comment,
                createdAt: note.createdAt,
                exam: note.exam,
              },
            ],
          },
        };
      } else if (tempNotes[note.semestre][note.matiere] === undefined) {
        tempNotes = {
          ...tempNotes,
          [note.semestre]: {
            ...tempNotes[note.semestre],
            [note.matiere]: [
              {
                note: note.note,
                coef: note.coef,
                comment: note.comment,
                createdAt: note.createdAt,
                exam: note.exam,
              },
            ],
          },
        };
      } else {
        tempNotes = {
          ...tempNotes,
          [note.semestre]: {
            ...tempNotes[note.semestre],
            [note.matiere]: [
              ...tempNotes[note.semestre][note.matiere],
              {
                note: note.note,
                coef: note.coef,
                comment: note.comment,
                createdAt: note.createdAt,
                exam: note.exam,
              },
            ],
          },
        };
      }
    });
    setnotes(tempNotes);
    let tempMoyennesMat = {};
    matieres.forEach((mat) => {
      let coef = promo.formation.coefs.filter(
        (cf) => cf.matiere["@id"] === mat["@id"]
      )[0].coefficient;

      tempMoyennesMat = {
        ...tempMoyennesMat,
        [mat["@id"]]: {},
      };
      promo.semestres.forEach((sem) => {
        let x = null;
        let p = null;
        if (tempNotes[sem["@id"]] !== undefined) {
          if (tempNotes[sem["@id"]][mat["@id"]] !== undefined) {
            tempNotes[sem["@id"]][mat["@id"]].forEach((el) => {
              if (el.note !== undefined) {
                x += parseFloat(el.note) * parseFloat(el.coef);
                p += parseFloat(el.coef);
              }
            });
          }
        }
        let moy = null;
        if (x !== null && p !== null) {
          moy = Math.round((x / p) * 100) / 100;
        }
        tempMoyennesMat = {
          ...tempMoyennesMat,
          [mat["@id"]]: {
            ...tempMoyennesMat[mat["@id"]],
            [sem["@id"]]: { moyenne: moy, coef: coef },
          },
        };
      });
    });
    setMoyennesMat(tempMoyennesMat);
    let tempMoyenneSemestre = {};
    promo.semestres.forEach((sem) => {
      let moy = null;
      let x = null;
      let p = null;
      let pFull = null;
      let optionPoint = 0;
      matieres.forEach((mat) => {
        if (tempMoyennesMat[mat["@id"]] !== undefined) {
          if (mat.name.slice(0, 2) !== "z-" && !mat.opt) {
            pFull += tempMoyennesMat[mat["@id"]][sem["@id"]].coef;
          }
          if (
            tempMoyennesMat[mat["@id"]][sem["@id"]] !== undefined &&
            tempMoyennesMat[mat["@id"]][sem["@id"]].moyenne !== null
          ) {
            if (mat.opt) {
              let tmpNote =
                parseFloat(tempMoyennesMat[mat["@id"]][sem["@id"]].moyenne) -
                10;
              if (tmpNote > 0) {
                optionPoint += tmpNote;
              }
              return;
            }
            if (tempMoyennesMat[mat["@id"]][sem["@id"]].coef !== 0) {
              x +=
                parseFloat(tempMoyennesMat[mat["@id"]][sem["@id"]].moyenne) *
                parseFloat(tempMoyennesMat[mat["@id"]][sem["@id"]].coef);
              p += parseFloat(tempMoyennesMat[mat["@id"]][sem["@id"]].coef);
            }
          }
        }
      });
      if (x !== null && p !== null) {
        moy = Math.round((x / p) * 100) / 100;
      }
      if (optionPoint > 0) {
        moy += Math.round((optionPoint / pFull) * 100) / 100;
      }
      tempMoyenneSemestre = {
        ...tempMoyenneSemestre,
        [sem["@id"]]: moy,
      };
    });
    setMoyennesSemestres(tempMoyenneSemestre);
    load();
  };

  const totalHeuresRtds = (val) => {
    let totalH = "0:00";
    val.forEach((v) => {
      let h = v.time.split("T")[1];
      h = h.split("+")[0];
      h = h.split(":")[0] + ":" + h.split(":")[1];
      totalH = addTime(totalH, h);
    });
    return totalH;
  };

  const totalHeuresAbs = (val) => {
    let totalH = 0;
    val.forEach((v) => {
      let h = parseFloat(heuresFormated(v.time));
      if (v.rattrapage) {
        totalH -= h;
      } else if (v.motif !== "Cours non obligatoire") {
        totalH += h;
      }
    });
    return totalH;
  };

  const totalHeures = (val) => {
    let totalH = 0;
    val.forEach((v) => {
      let h = parseFloat(heuresFormated(v.time));
      if (v.motif !== "Cours non obligatoire") {
        totalH += h;
      }
    });
    return totalH;
  };

  const bulletinContent = (semestre) => {
    let moyExamPond = null;
    let examCoef = null;
    let abs =
      absences !== null
        ? absences.filter((e) => e.semestre["@id"] === semestre["@id"])
        : [];
    let absNoJustify =
      abs.length > 0 ? abs.filter((el) => !el.justify && !el.rattrapage) : [];
    let absJustify =
      abs.length > 0 ? abs.filter((el) => el.justify && !el.rattrapage) : [];
    let rattrapages = abs.length > 0 ? abs.filter((el) => el.rattrapage) : [];
    let rtds =
      retards !== null
        ? retards.filter((e) => e.semestre === semestre["@id"])
        : [];
    let sanctionsSemestre = sanctions.filter(
      (s) => s.semestre === semestre["@id"]
    );
    return (
      <div>
        <div className="d-flex justify-content-center">
          {user.role <= 3 ? (
            <div className="text-right mx-2">
              <ReactHTMLTableToExcel
                className="btn btn-outline-primary"
                table={"tab" + semestre["@id"]}
                filename={
                  "Bulletin-" +
                  eleve.user.name +
                  "-" +
                  eleve.user.firstName +
                  "-" +
                  semestre.name
                }
                sheet="Export"
                buttonText="Exporter ---> Excel"
              />
            </div>
          ) : null}

          {user.role <= 3 || semestre.validate ? (
            <div className="text-left mx-2">
              <BulletinPDF
                promoUri={promo["@id"]}
                eleve={eleve}
                semestre={semestre}
                matieres={matieres}
                comments={comments.filter(
                  (c) => c.semestre === semestre["@id"]
                )}
                commentSemestre={
                  commentsSemestre.filter(
                    (c) => c.semestre === semestre["@id"]
                  )[0]
                }
                abs={
                  abs.length > 0
                    ? "" +
                      convertDecimal(totalHeuresAbs(abs)) +
                      " heures d'absence"
                    : "Pas d'absence ce semestre"
                }
                noJustify={
                  absNoJustify.length > 0
                    ? "" +
                      convertDecimal(totalHeures(absNoJustify)) +
                      " heures d'absence injustifiée"
                    : "Pas d'absence injustifiée"
                }
                justify={
                  absJustify.length > 0
                    ? "(dont " +
                      convertDecimal(totalHeures(absJustify)) +
                      " justifiées)"
                    : abs.length > 0
                    ? "(dont 0 justifiée)"
                    : ""
                }
                rattrapages={
                  rattrapages.length > 0
                    ? "" +
                      convertDecimal(totalHeures(rattrapages)) +
                      " heures de rattrapage"
                    : "Pas de rattrapage"
                }
                rtd={
                  rtds.length > 0
                    ? `${rtds.length} retards ce semestre`
                    : "Pas de retard ce semestre"
                }
                sanctions={sanctionsSemestre}
              />
            </div>
          ) : null}
        </div>
        <div className="d-flex flex-wrap justify-content-center text-info">
          <div className="col-xl-5 m-1 border border-danger">
            <h5 className="mt-3">
              <FontAwesomeIcon className="text-danger" icon="user-times" />{" "}
              &nbsp;
              <u>Absences du semestre</u>
            </h5>
            {abs.length === 0 ? (
              "pas d'absence ce semestre."
            ) : (
              <p>
                {abs.length} {abs.length > 1 ? "absences" : "absence"} ce
                semestre ({convertDecimal(totalHeuresAbs(abs))} Heures)
                <br />
                <span
                  type="button"
                  className="badge badge-danger"
                  onClick={() => {
                    setAbsSelected(abs);
                  }}
                  data-toggle="modal"
                  data-target="#ModalViewAbs"
                >
                  Détails
                </span>
              </p>
            )}
          </div>
          <div className="col-xl-5 m-1 border border-warning">
            <h5 className="mt-3">
              <FontAwesomeIcon className="text-warning" icon="user-times" />{" "}
              &nbsp;
              <u>Retards/départs anticipés du semestre</u>
            </h5>
            {rtds.length === 0 ? (
              "pas de retard ce semestre."
            ) : (
              <p>
                {rtds.length}{" "}
                {rtds.length > 1
                  ? "retards/départs anticipés"
                  : "retard/départ anticipé"}{" "}
                ce semestre ({totalHeuresRtds(rtds)})<br />
                <span
                  type="button"
                  className="badge badge-warning"
                  onClick={() => {
                    setRtdSelected(rtds);
                  }}
                  data-toggle="modal"
                  data-target="#ModalViewRtd"
                >
                  Détails
                </span>
              </p>
            )}
          </div>
        </div>
        <table
          className="table table-responsive-lg table-sm table-bordered border-0"
          id={"tab" + semestre["@id"]}
        >
          <thead>
            <tr>
              <th className="px-2">Matieres</th>
              <th className="px-2">Coef</th>
              <th className="px-2">Notes</th>
              <th className="px-2">Moyennes</th>
              <th className="px-2 text-primary font-weight-bold">
                Examen Blanc
              </th>
              <th className="px-2">Commentaires</th>
            </tr>
          </thead>
          <tbody>
            {matieres.map((matiere, i) => {
              let noteExamMat = null;
              let nbNoteExamMat = 0;
              return (
                <tr className="text-left" key={i}>
                  <td className="px-2">
                    <strong>{matiere.name}</strong>
                  </td>
                  <td className="text-center font-weight-bold">
                    {matiere.opt ? (
                      <span className="font-small">Option</span>
                    ) : (
                      convertDecimal(
                        moyennesMat[matiere["@id"]][semestre["@id"]].coef
                      )
                    )}
                  </td>
                  <td
                    className={
                      matiere.name.slice(0, 2) === "z-"
                        ? "bg-secondary"
                        : "px-2"
                    }
                  >
                    {matiere.name.slice(0, 2) === "z-"
                      ? ""
                      : notes !== null
                      ? notes[semestre["@id"]] !== undefined &&
                        notes[semestre["@id"]][matiere["@id"]] !== undefined
                        ? notes[semestre["@id"]][matiere["@id"]].map(
                            (note, i, tab) => (
                              <span
                                key={i}
                                className="ml-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={
                                  dateFormated(note.createdAt) +
                                  " - " +
                                  note.comment
                                }
                              >
                                {note.note !== null && note.note !== undefined
                                  ? convertDecimal(note.note)
                                  : "NE"}
                                <sup>
                                  <em>
                                    {note.note !== null &&
                                    note.note !== undefined
                                      ? "(" + convertDecimal(note.coef) + ")"
                                      : ""}
                                  </em>
                                </sup>
                                {tab.length > 1 && i + 1 < tab.length
                                  ? " | "
                                  : ""}
                              </span>
                            )
                          )
                        : "pas de notes"
                      : "pas de notes"}
                  </td>
                  <td className="px-2 text-center">
                    {(matiere.name.slice(0, 2) !== "z-" ||
                      user.role <= 4 ||
                      semestre.validate) &&
                    moyennesMat !== null ? (
                      <strong>
                        {convertDecimal(
                          moyennesMat[matiere["@id"]][semestre["@id"]].moyenne
                        )}
                      </strong>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    className={
                      matiere.name.slice(0, 2) === "z-"
                        ? "bg-secondary"
                        : "px-2 text-center text-primary font-weight-bold"
                    }
                  >
                    {matiere.name.slice(0, 2) === "z-"
                      ? null
                      : notes !== null &&
                        notes[semestre["@id"]] !== undefined &&
                        notes[semestre["@id"]][matiere["@id"]] !== undefined
                      ? notes[semestre["@id"]][matiere["@id"]]
                          .filter((e) => e.exam && e.note !== undefined)
                          .map((note, i, tab) => {
                            let coefMat = promo.formation.coefs.filter(
                              (cf) => cf.matiere["@id"] === matiere["@id"]
                            )[0].coefficient;
                            noteExamMat += parseFloat(note.note);
                            nbNoteExamMat++;
                            if (i + 1 === tab.length) {
                              if (noteExamMat !== null) {
                                if (noteExamMat > 0) {
                                  moyExamPond +=
                                    (noteExamMat / nbNoteExamMat) * coefMat;
                                } else {
                                  moyExamPond += noteExamMat;
                                }
                                examCoef += parseFloat(coefMat);
                              }

                              return (
                                <span
                                  key={i}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    nbNoteExamMat > 1
                                      ? `${nbNoteExamMat} Notes`
                                      : `${nbNoteExamMat} Note`
                                  }
                                >
                                  {noteExamMat !== null
                                    ? convertDecimal(
                                        Math.round(
                                          (noteExamMat / nbNoteExamMat) * 100
                                        ) / 100
                                      )
                                    : "NE"}
                                </span>
                              );
                            } else {
                              return null;
                            }
                          })
                      : null}
                  </td>
                  <td
                    className={
                      matiere.name.slice(0, 2) === "z-"
                        ? "bg-secondary"
                        : "px-2"
                    }
                  >
                    <p className="d-flex m-0 justify-content-between">
                      <span>
                        {matiere.name.slice(0, 2) === "z-" ? null : comments !==
                            null &&
                          comments.filter(
                            (c) =>
                              c.semestre === semestre["@id"] &&
                              c.matiere === matiere["@id"]
                          ).length > 0 ? (
                          comments
                            .filter(
                              (c) =>
                                c.semestre === semestre["@id"] &&
                                c.matiere === matiere["@id"]
                            )
                            .map((c, i, a) => {
                              if (user.role <= 3) {
                                return (
                                  <React.Fragment key={i}>
                                    <span>
                                      *{" "}
                                      <em>
                                        <u>
                                          {c.formateur.user.firstName}{" "}
                                          {c.formateur.user.name}
                                        </u>
                                      </em>{" "}
                                      : {c.comment}{" "}
                                      <FontAwesomeIcon
                                        data-toggle="modal"
                                        data-target="#ModalModifCom"
                                        title="Modifier"
                                        className="text-info"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          setCommentSelected({
                                            ...c,
                                            formateur: c.formateur["@id"],
                                          })
                                        }
                                        icon="edit"
                                      />{" "}
                                      <FontAwesomeIcon
                                        className="text-danger"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              "Supprimer le commentaire ?"
                                            )
                                          ) {
                                            deleteComment(c["@id"]);
                                          }
                                        }}
                                        icon="trash-alt"
                                      />
                                    </span>
                                    {i + 1 < a.length ? <br /> : null}
                                  </React.Fragment>
                                );
                              } else if (
                                formateur !== undefined &&
                                c.formateur["@id"] === formateur["@id"] //**************** A modifier */
                              ) {
                                return (
                                  <React.Fragment key={i}>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        setCommentSelected(
                                          JSON.parse(JSON.stringify(c))
                                        )
                                      }
                                      data-toggle="modal"
                                      data-target="#ModalModifCom"
                                      title="Modifier"
                                    >
                                      {" "}
                                      *{" "}
                                      <em>
                                        <u>
                                          {c.formateur.user.firstName}{" "}
                                          {c.formateur.user.name}
                                        </u>
                                      </em>{" "}
                                      : {c.comment}{" "}
                                      <FontAwesomeIcon
                                        className="text-primary"
                                        icon="edit"
                                      />
                                    </span>
                                    {i + 1 < a.length ? <br /> : null}
                                  </React.Fragment>
                                );
                              } else {
                                if (!semestre.validate && user.role > 4) {
                                  return (
                                    <React.Fragment key={i}>
                                      <span>Semestre non cloturé</span>
                                      {i + 1 < a.length ? <br /> : null}
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <React.Fragment key={i}>
                                      <span>
                                        {" "}
                                        *{" "}
                                        <em>
                                          <u>
                                            {c.formateur.user.firstName}{" "}
                                            {c.formateur.user.name}
                                          </u>
                                        </em>{" "}
                                        : {c.comment}
                                      </span>
                                      {i + 1 < a.length ? <br /> : null}
                                    </React.Fragment>
                                  );
                                }
                              }
                            })
                        ) : (
                          <span>
                            {!semestre.validate && user.role > 4 ? (
                              "Semestre non cloturé"
                            ) : matiere.name.slice(0, 2) !== "z-" ? (
                              <span>Pas de commentaire</span>
                            ) : null}
                          </span>
                        )}
                      </span>
                      {(user.role <= 3 &&
                        matiere.name.slice(0, 2) !== "z-" &&
                        comments !== null &&
                        matiere.name.slice(0, 2) !== "z-") ||
                      (user.role > 3 &&
                        user.role <= 4 &&
                        formateur.matieres.filter(
                          (m) => m["@id"] === matiere["@id"]
                        ).length > 0 &&
                        comments !== null &&
                        comments.filter(
                          (c) =>
                            c.semestre === semestre["@id"] &&
                            c.matiere === matiere["@id"] &&
                            c.formateur["@id"] === formateur["@id"]
                        ).length === 0 &&
                        matiere.name.slice(0, 2) !== "z-") ? (
                        <span
                          className="text-info"
                          key={i}
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            setCommentSelected({
                              eleve: eleve["@id"],
                              semestre: semestre["@id"],
                              matiere: matiere["@id"],
                              formateur:
                                formateur !== undefined && formateur["@id"]
                                  ? formateur["@id"]
                                  : null,
                              comment: "",
                            })
                          }
                          data-toggle="modal"
                          data-target="#ModalModifCom"
                          title="Ajouter"
                        >
                          <FontAwesomeIcon icon="plus-circle" />
                        </span>
                      ) : null}
                    </p>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="text-right" colSpan="3">
                <strong>Moyenne du semestre : </strong>
              </td>
              <td>
                {moyennesSemestres !== null ? (
                  <strong>
                    {convertDecimal(moyennesSemestres[semestre["@id"]])}
                  </strong>
                ) : (
                  ""
                )}
              </td>
              <td className="text-primary font-weight-bold">
                {moyExamPond !== null && examCoef !== null
                  ? convertDecimal(
                      Math.round((moyExamPond / examCoef) * 100) / 100
                    )
                  : null}
              </td>
            </tr>
            <tr>
              <td colSpan="6">
                <CommentGeneral semestre={semestre} />
              </td>
            </tr>
          </tbody>
        </table>
        <ListSanctions
          idAccordion={`sanctions${semestre.name.split(" ").join("-")}`}
          sanctions={sanctionsSemestre}
          motifs={motifs}
          setSanctionSelected={setSanctionSelected}
          deleteSanction={deleteSanction}
          eleve={eleve}
          user={user}
          show={true}
          titre={`Vie Scolaire du ${semestre.name}`}
          site={promo.site}
        />
      </div>
    );
  };

  const content = (
    <div className="accordion animFadeIn my-3" id={idAccordion}>
      {user.role < 5 ? (
        <ModifCommentMat
          selected={commentSelected}
          promo={promo["@id"]}
          setMaj={setMaj}
        />
      ) : null}
      {user.role <= 3 ? (
        <EditAppreciationSem appreciation={appSelected} maj={setMaj} />
      ) : null}

      {promo.semestres !== undefined && loaded ? (
        promo.semestres.length > 0 ? (
          promo.semestres.map((semestre, i) => {
            return (
              <div key={i}>
                <div className="card shadow rounded mx-3">
                  <div
                    className="card-header bg-info p-0"
                    id={"heading" + eleve.id + "-" + i}
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block font-weight-bold text-white pb-0"
                        type="button"
                        data-toggle="collapse"
                        data-target={"#collapse" + eleve.id + "-" + i}
                        aria-expanded={
                          semestre["@id"] === semestreActif ? "true" : "false"
                        }
                        aria-controls={"collapse" + eleve.id + "-" + i}
                      >
                        <h5 className="m-0">{semestre.name}</h5>{" "}
                        <FontAwesomeIcon icon="chevron-down" />
                      </button>
                    </h2>
                  </div>

                  <div
                    className={
                      semestre["@id"] === semestreActif
                        ? "collapse show"
                        : "collapse"
                    }
                    id={"collapse" + eleve.id + "-" + i}
                    aria-labelledby={"heading" + eleve.id + "-" + i}
                    data-parent={"#" + idAccordion}
                  >
                    <div className="card-body">{bulletinContent(semestre)}</div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <MsgAlert
            msg="Les semestres n'ont pas été initiailisés"
            type="danger mx-3 h5"
          />
        )
      ) : (
        <Spinner />
      )}
    </div>
  );

  return (
    <React.Fragment>
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <ViewAbsEleve abs={absSelected} />
      <ViewRtdEleve rtd={rtdSelected} />
      {content}
    </React.Fragment>
  );
};

export default ListNotes;
