import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditSuiviAfest = ({ setMaj, listPromos }) => {
  const { url } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [competencesSelected, setCompetencesSelected] = useState([]);
  const [saveNotes, setSaveNotes] = useState(false);
  const [promos, setPromos] = useState(null);
  const [formation, setFormation] = useState(null);
  const [promo, setpromo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [eleves, setEleves] = useState(null);
  const [msg, setMsg] = useState(null);
  const [suivi, setSuivi] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    validate: false,
    promo: promo,
    time: 0
  });

  useEffect(() => {
    if (listPromos !== null && listPromos !== promos) {
      setPromos(listPromos);
      setpromo(null)
      setBlocs([]);
      setLoaded(true);
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPromos]);

  useEffect(() => {
    if (promo !== null) {
      loadEleves();
      setBlocs([]);
      loadBlocs();
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (saveNotes) {
      init();
      setLoaded(true);
      setMaj(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveNotes]);

  const init = () => {
    setSuivi({
      validate: false,
      createdAt: suivi.createdAt,
      validateAt: suivi.validateAt,
      time: suivi.time,
      promo: promo,
      name: suivi.name,
    });
    setCompetencesSelected([]);
    setSaveNotes(false);
  };

  const loadEleves = () => {
    if (promo !== "") {
      let uri = `${url}api/eleves?promos=${promo}&user.actif=1`;
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setEleves(res["hydra:member"]);
        } else {
          setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setEleves(null);
    }
  };

  const loadBlocs = () => {
    let uri = url + "api/bloc_afests?formation=" + formation;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const removeCompetence = (comp) => {
    let tmp = [...competencesSelected];
    tmp.forEach((b, i) => {
      if (b["@id"] === comp["@id"]) {
        tmp.splice(i, 1);
      }
    });
    setCompetencesSelected(tmp);
  };

  const addCompetence = (comp) => {
    setCompetencesSelected([...competencesSelected, comp]);
  };

  const validate = () => {
    return (
      suivi.createdAt &&
      suivi.validateAt &&
      suivi.name &&
      suivi.time &&
      suivi.promo &&
      competencesSelected.length > 0
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveNotes(false);
    setLoaded(false);
    let uri = url + "api/eval_afests";
    eleves.forEach(async (e, i) => {
      let result = await GetBddProvider(
        url + "api/entreprises?eleves=" + e["@id"]
      );
      let tuteur = typeof result === "object" ? result["hydra:member"] : null;
      let tmp = { ...suivi, eleve: e["@id"] };
      if (tuteur.length > 0) {
        tmp = { ...tmp, tuteur: tuteur[0]["@id"] };
      }
      let save = await PostBddProvider(uri, tmp);
      let iriEval =
        typeof save === "object" && save.id
          ? "/api/eval_afests/" + save.id
          : null;
      if (iriEval !== null) {
        let erreurs = [];
        competencesSelected.forEach(async (comp, i) => {
          let note = { competence: comp["@id"], eval: iriEval };
          let saveNote = await PostBddProvider(url + "api/note_afests", note);
          if (typeof saveNote !== "object" || !saveNote.id) {
            erreurs = [...erreurs, comp.name];
          }
          if (i + 1 === competencesSelected.length) {
            setSaveNotes(true);
            if (erreurs.length > 0) {
              setMsg({
                txt:
                  "Erreur d'enregistrement de " +
                  erreurs.length +
                  " competences",
                type: "danger",
              });
              autoCloseMsg(setMsg, 5000);
            } else {
              setMsg({
                txt: "L'évaluation a bien été enregistrée",
                type: "success",
              });
              autoCloseMsg(setMsg, 5000);
            }
          }
        });
      } else {
        setMsg({ txt: "erreur d'enregistrement de l'éval", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalSuivi"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
              {suivi.id ? "Modifier" : "Ajouter"} un suivi
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null ? (
              <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
            ) : null}
            {!loaded || promos === null ? (
              <div className="pb-5">
                <Spinner />
              </div>
            ) : (
              <React.Fragment>
                <form>
                  <div className="form-row">
                    <div className="form-group col-lg-5">
                      <label>
                        Date de création
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        disabled
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={suivi.createdAt || ""}
                        onChange={(e) => {
                          setSuivi({
                            ...suivi,
                            createdAt: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-lg-5">
                      <label>
                        Date de fin de saisie
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={suivi.validateAt || ""}
                        onChange={(e) => {
                          setSuivi({
                            ...suivi,
                            validateAt: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-lg-2">
                      <label>Durée prévue (h)</label>
                      <input
                        type="number"
                        title="Durée de l'évaluation"
                        step="0.5"
                        value={suivi.time || ""}
                        className={
                          suivi.time !== null &&
                          suivi.time !== undefined &&
                          suivi.time !== ""
                            ? "form-control"
                            : "is-invalid form-control"
                        }
                        onChange={(e) =>
                          setSuivi({
                            ...suivi,
                            time: parseFloat(e.target.value),
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label>Promo</label>
                      <select
                        className="form-control"
                        value={promo || ""}
                        onChange={(e) => {
                          setpromo(e.target.value);
                          setFormation(
                            promos.filter((p) => p["@id"] === e.target.value)[0]
                              .formation["@id"]
                          );
                        }}
                      >
                        <option value="" disabled>
                          Sélectionnez une promo
                        </option>
                        {promos.map((p, i) => (
                          <option value={p["@id"]} key={i}>
                            {p.site.name} {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-lg-6">
                      <label>Intitulé</label>
                      <input
                        className="form-control"
                        type="text"
                        value={suivi.name || ""}
                        onChange={(e) =>
                          setSuivi({ ...suivi, name: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-6 border-right">
                      <h5>Liste des blocs</h5>
                      {blocs.map((bloc) => {
                        return (
                          <div
                            key={bloc.id}
                          >
                            <span className="font-weight-bold">
                              {bloc.name}
                            </span>{" "}
                            <br />
                            {bloc.competences.sort(triByName).map((comp, i) => {
                              let tmpComp = competencesSelected.filter(
                                (c) => c["@id"] === comp["@id"]
                              );
                              return (
                                <p key={i} className="p-0 m-0 text-left">
                                  <FontAwesomeIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      tmpComp.length > 0
                                        ? removeCompetence(comp)
                                        : addCompetence(comp);
                                    }}
                                    className={
                                      tmpComp.length > 0
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                    icon={
                                      tmpComp.length > 0
                                        ? "arrow-circle-left"
                                        : "arrow-circle-right"
                                    }
                                    title={
                                      tmpComp.length > 0
                                        ? "Retirer la compétence"
                                        : "Ajouter la compétence"
                                    }
                                  />{" "}
                                  <em
                                    className={
                                      tmpComp.length > 0 ? "text-success" : null
                                    }
                                  >
                                    - {comp.name}
                                  </em>
                                </p>
                              );
                            })}
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-6">
                      <h5>Blocs sélectionnés</h5>
                      {blocs.map((b) => {
                        let tmpComp = [];
                        b.competences.forEach((c) => {
                          competencesSelected.forEach((cs) => {
                            if (cs["@id"] === c["@id"]) {
                              tmpComp = [...tmpComp, cs];
                            }
                          });
                        });
                        if (tmpComp.length > 0) {
                          return (
                            <div key={b.id}>
                              <span className="font-weight-bold">{b.name}</span>
                              {tmpComp.sort(triByName).map((c) => (
                                <p
                                  key={c["@id"]}
                                  className="p-0 m-0 text-left text-success"
                                >
                                  <FontAwesomeIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeCompetence(c)}
                                    className="text-danger"
                                    icon="times-circle"
                                    title="Retirer la compétence"
                                  />{" "}
                                  <em>- {c.name}</em>
                                </p>
                              ))}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </form>
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={!validate()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuiviAfest;
