import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import ProgContext from "../../../Context/ProgContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ModifProgression = ({ selected, maj }) => {
  const { url } = useContext(AppContext);
  const { setSelected } = useContext(ProgContext);
  const [progression, setProgression] = useState(selected);
  const [save, setSave] = useState(true);

  const [msgLocal, setMsgLocal] = useState(null);

  useEffect(() => {
    if (progression !== selected) {
      setProgression(selected);
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const init = () => {
    if (selected !== progression) {
      let time = selected.time;
      let date = selected.createdAt;
      if (time.length > 5) {
        time =
          selected.time.split("T")[1].split(":")[0] +
          ":" +
          selected.time.split("T")[1].split(":")[1];
      }
      if (date.length > 10) {
        date = selected.createdAt.split("T")[0];
      }
      if (time !== selected.time || date !== selected.createdAt) {
        setSelected({ ...selected, createdAt: date, time: time });
      }
    }
  };

  const isValide = () => {
    return (
      progression.createdAt &&
      progression.time &&
      progression.description.length > 0
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let uri = url + progression["@id"].slice(1);
    let update = {
      createdAt: progression.createdAt,
      time: progression.time,
      description: progression.description,
      activate: progression.activate,
      appel: progression.appel,
    };
    UpdateBddProvider(uri, update).then((res) => {
      setSave(true);
      if (typeof res === "object" && res.id) {
        maj();
        setMsgLocal({ txt: "Modifcation enregistrée", type: "success" });
        autoCloseMsg(setMsgLocal, 5000);
      } else {
        setMsgLocal({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsgLocal, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalModifProg"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;Modifier la progression
                pédagogique
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msgLocal !== null ? (
                <div className="mx-4">
                  <MsgAlert
                    msg={msgLocal.txt}
                    type={msgLocal.type}
                    close={() => autoCloseMsg(setMsgLocal)}
                  />
                </div>
              ) : null}
              {!save ? (
                <Spinner />
              ) : progression === null ? (
                <p className="text-danger">Pas de progression sélectionnée</p>
              ) : (
                <div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={progression.createdAt || ""}
                        onChange={(e) => {
                          if (
                            new Date(e.target.value) > new Date() ||
                            e.target.value === ""
                          ) {
                            setProgression({
                              ...progression,
                              activate: false,
                              createdAt: e.target.value,
                            });
                          } else {
                            setProgression({
                              ...progression,
                              createdAt: e.target.value,
                            });
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>
                        Durée{" "}
                        <em>
                          <small>(de 00:15 à 05:00)</small>
                        </em>
                      </label>
                      <input
                        type="time"
                        title="Heures complètes entre 00:15 et 05:00"
                        min="00:15"
                        max="05:00"
                        step="900"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={progression.time || "00:00"}
                        className="form-control"
                        onChange={(e) =>
                          setProgression({
                            ...progression,
                            time: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <hr />
                    <strong>
                      <em>
                        <u>{selected.promos.length > 1 ? "Promos" : "Promo"}</u>
                      </em>
                    </strong>
                    <p className="mt-1">
                      {selected.promos.map((promo, i) => {
                        let temp = promo.name;
                        if (
                          selected.promos.length > 1 &&
                          i + 1 < selected.promos.length
                        ) {
                          temp += " / ";
                        }
                        return <span key={i}>{temp}</span>;
                      })}
                    </p>
                    <strong>
                      <em>
                        <u>Elément de progression</u>
                      </em>
                    </strong>
                    <p className="mt-1">{selected.progressionItem[0].name}</p>
                  </div>
                  <div className="form-group">
                    <hr />
                    <label>Description</label>
                    <textarea
                      type="textarea"
                      className="form-control"
                      placeholder="Description de la session"
                      required
                      rows="3"
                      value={progression.description || ""}
                      onChange={(e) =>
                        setProgression({
                          ...progression,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <div className="custom-control custom-switch p-0">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        checked={progression.activate || false}
                        disabled={
                          new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "" ||
                          !progression.description ||
                          progression.description.length < 10
                        }
                        onChange={(e) =>
                          setProgression({
                            ...progression,
                            activate: !progression.activate,
                            appel: progression.activate
                              ? false
                              : progression.appel,
                          })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Réalisée
                      </label>
                      <div
                        className="d-flex flex-column"
                        class="form-text text-muted font-italic"
                      >
                        {(!progression.description ||
                          progression.description.length < 10) && (
                          <small className="d-block lh-1 font-small mb-1">
                            - La description doit contenire au moins 10
                            caractères, pour pouvoir cocher "Réalisée".
                          </small>
                        )}
                        {(new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "") && (
                          <small className="d-block lh-1 font-small">
                            - La date doit être antérieur ou égale à la datre du
                            jour, pour pouvoir cocher "Réalisée".
                          </small>
                        )}
                      </div>
                    </div>
                    {/* <div className="custom-control custom-switch col-6">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="CheckboxAppel1"
                        checked={progression.appel || false}
                        disabled={
                          new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "" ||
                          !progression.activate
                        }
                        onChange={() => {
                          setProgression({
                            ...progression,
                            appel: !progression.appel,
                          });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="CheckboxAppel1"
                      >
                        Appel fait
                      </label>
                    </div> */}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fermer
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info"
                      disabled={!isValide()}
                    >
                      Mettre à jour
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModifProgression;
