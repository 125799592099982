import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditMatiere from "../contents/edits/EditMatiere";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const Matieres = () => {
  const { url } = useContext(AppContext);
  const [loaded, setloaded] = useState(false);
  const [matieres, setmatieres] = useState(null);
  const [selected, setSelected] = useState(null);
  const [maj, setmaj] = useState(false);
  const [msg, setMsg] = useState(null);

  const load = () => {
    let uri = url + "api/matieres";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setmatieres(res["hydra:member"].sort(triByName));
        setloaded(true);
      }
    });
  };

  const delMat = (iri) => {
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      if (res === 204) {
        setmaj(true);
        setMsg({ txt: "la matière a bien été supprimée !", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur de suppression !", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setmaj(false);
      setloaded(false);
      load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap justify-content-md-between justify-content-center m-3">
        <div></div>
        <h2 className="m-0">liste des matières</h2>
        <button
          type="button"
          className="btn btn-info"
          data-toggle="modal"
          data-target="#Modal"
        >
          <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
        </button>
      </span>
      <EditMatiere maj={setmaj} selected={selected} />
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      {!loaded ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-wrap justify-content-center">
          {matieres.map((matiere, i) => {
            return (
              <div
                className="card shadow border-info col-md-4 p-0 m-3"
                style={{ maxWidth: "20rem" }}
                key={i}
              >
                <div className="card-header d-flex justify-content-between text-info h-100 text-left">
                  <div>
                    <h5>{matiere.name}</h5>
                    {matiere.hidden && (
                      <span className="badge badge-pill badge-danger">
                        Cacher
                      </span>
                    )}
                    {matiere.opt && (
                      <span className="badge badge-pill badge-warning">
                        Option
                      </span>
                    )}
                  </div>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        className="text-success"
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#Modal"
                        onClick={() => {
                          setSelected(matiere);
                        }}
                        icon="edit"
                      />
                    </div>
                    <div>
                      <FontAwesomeIcon
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (window.confirm("Supprimer la progression ?")) {
                            delMat(matiere["@id"]);
                          }
                        }}
                        icon="trash-alt"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Matieres;
