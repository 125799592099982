import GetBddProvider from "../../../Providers/GetBddProvider";
import { autoCloseMsg } from "../messagesUtils";

export const periode = (seance, cours) => {
  let H;
  if (seance) {
    H = parseInt(seance.Fin.split(":")[0]);
  } else {
    H = new Date(cours.END).getHours();
  }
  if (H < 13) {
    return "Matin";
  } else {
    return "Après-midi";
  }
};

export const duree = (seance, cours) => {
  let h;
  let m;
  if (seance) {
    let time = seance.Duree.split(":");
    h = time[0];
    m = time[1];
  } else {
    let start = new Date(cours.START);
    let end = new Date(cours.END);
    let diff = new Date(end - start);
    h = diff.getUTCHours() < 10 ? "0" + diff.getUTCHours() : diff.getUTCHours();
    m = diff.getMinutes() < 10 ? "0" + diff.getMinutes() : diff.getMinutes();
  }
  return h + ":" + m;
};

export const getPromo = async (
  url,
  urlEdusign,
  token,
  cours,
  setPromos,
  setMsg,
  setLoad
) => {
  let groupsDetail = [];
  let error = [];
  for (const g of cours.SCHOOL_GROUP) {
    let uriGroup = urlEdusign["GROUPS"] + "/" + g;
    try {
      await GetBddProvider(uriGroup, token).then((res) => {
        if (typeof res === "object" && res.result) {
          groupsDetail.push(res.result);
        } else {
          error.push("erreur de chargment Edusign !");
        }
      });
    } catch (err) {
      error.push("erreur de chargment Edusign !");
    }
  }

  let uri = url + "api/promos?";
  groupsDetail.forEach((group, i, groups) => {
    uri += groups.length > 1 ? "IdEdusign[]=" : "IdEdusign=";
    uri += group.PARENT ? group.PARENT : group.ID;
    uri += i + 1 < groups.length ? "&" : "";
  });
  await GetBddProvider(uri).then((res) => {
    if (typeof res === "object") {
      setPromos(res["hydra:member"]);
    } else {
      error.push("Erreur de chargement de la promo");
    }
  });

  if (error.length > 0) {
    setMsg({ txt: error.join(", "), type: "warning" });
  }
  setLoad(true);
};
export const getPromoGalia = async (
  url,
  seance,
  setPromos,
  setMsg,
  setLoad
) => {
  let uri = url + "api/promos?";
  let tempPromo = [];
  let uriPromo = `${uri}idGalia=${seance.IDAction}`;
  await GetBddProvider(uriPromo).then((res) => {
    setLoad(true);
    if (typeof res === "object") {
      tempPromo = res["hydra:member"];
    } else {
      setMsg({ txt: "Erreur de chargement de la promo", type: "warning" });
      autoCloseMsg(setMsg, 2000);
    }
  });
  if (tempPromo.length === 0) {
    let uriTC = `${uri}tc=${seance.IDAction}`;
    await GetBddProvider(uriTC).then((res) => {
      setLoad(true);
      if (typeof res === "object") {
        tempPromo = res["hydra:member"];
      } else {
        setMsg({ txt: "Erreur de chargement de la promo", type: "warning" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  }
  setPromos(tempPromo);
};

export const getEleves = (url, promos, setEleves, setMsg) => {
  let uri = url + "api/eleves?";
  promos.forEach((promo, i, promos) => {
    uri += promos.length > 1 ? "promos[]=" : "promos=";
    uri += promo["@id"];
    uri += i + 1 < promos.length ? "&" : "";
  });
  GetBddProvider(uri).then((res) => {
    if (typeof res === "object") {
      setEleves(res["hydra:member"]);
    } else {
      setMsg({ txt: "Erreur de chargement des étudiants", type: "warning" });
      autoCloseMsg(setMsg, 2000);
    }
  });
};
